exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-post-payment-[id]-js": () => import("./../../../src/pages/post-payment/[id].js" /* webpackChunkName: "component---src-pages-post-payment-[id]-js" */),
  "component---src-templates-place-js": () => import("./../../../src/templates/Place.js" /* webpackChunkName: "component---src-templates-place-js" */),
  "component---src-templates-place-with-video-banner-js": () => import("./../../../src/templates/PlaceWithVideoBanner.js" /* webpackChunkName: "component---src-templates-place-with-video-banner-js" */)
}

